import { mapState, mapActions } from 'pinia';
import { VueOfflineStorage } from 'vue-offline';
import { useDbStore } from '@/stores/db-st';
export default {
  data() {
    return {
      // primaryColor: {
      //   color: '#38BCD9',
      // },
      // secondaryColor:{
      //   color: '#38BCD9',
      // }
    };
  },
  watch: {},
  computed: {
    ...mapState(useDbStore, ['customer', 'isMedtenCustomer']),
    getBaseUrlByDomain() {
      let domain = new URL(window.location.href);
      if (domain['host'] && domain['host'] != '') {
        const allowedTopLevelDomain = ['.com', '.eu', 'localhost'];
        if (
          allowedTopLevelDomain.some((element) =>
            domain['host'].includes(element)
          )
        ) {
          return process.env.VUE_APP_US_PRODUCT_IMAGES_CDN_BASE_URL;
        }
        return process.env.VUE_APP_CN_PRODUCT_IMAGES_CDN_BASE_URL;
      }
      return process.env.VUE_APP_US_PRODUCT_IMAGES_CDN_BASE_URL;
    },
    getCurrency() {
      let domain = new URL(window.location.href);
      let region_id = this.customer.marketing_region_id;
      if (region_id === 1) {
        return '$';
      } 
      if (region_id === 2) {
        return '€';
      } 
      if (region_id === 3) {
        return '¥';
      }

      if (domain['host'].includes('.eu') && this.$i18n.locale === 'en') {
        return '€';
      } else if (this.$i18n.locale === 'en') {
        return '$';
      } else if (this.$i18n.locale === 'zh') {
        return '¥';
      }

      return '$'; // Default
    },
    isChinese() {
      return this.$i18n.locale === 'zh';
    },
    isBranded() {
      return !this.isMedtenCustomer;
    },
    displayStockInfoForCustomer() {
      if (this.isMedtenCustomer) {
        return true;
      }
      if (this.customer.display_stock === true) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getFullUrl(url) {
      let fullUrl = this.getBaseUrlByDomain + url;
      if (url.indexOf('https://') != -1 || url.indexOf('http://') != -1) {
        fullUrl = url;
      }
      return fullUrl;
    },
    clearSearch() {
      VueOfflineStorage.set('refine_filter_options', null);
      VueOfflineStorage.set('refine_models', null);
      VueOfflineStorage.set('refine_brands', null);
      VueOfflineStorage.set('refine_categories', null);
    },
    getShouldShowListPrice(isPart, isLoggedIn, showListPrice) {
      if (this.isBranded) {
        return this.customer.display_list_price;
      }
      if (isPart && !isLoggedIn) {
        return false;
      }
      return this.customer.display_list_price || showListPrice;
    },
    translateText(sourceObj, objKey = 'name', noEn = false) {
      const currentLocale = this.$i18n.locale;
      let propKey = null;
      if (noEn === true) {
        propKey =
          currentLocale === 'zh' ? objKey + '_' + currentLocale : objKey;
      } else {
        propKey = objKey + '_' + currentLocale;
      }
      if (sourceObj) {
        return sourceObj[propKey] || sourceObj[objKey + '_' + 'en'] || '';
      }
      return '';
    },
    translatePrice(sourceObj, objKey) {
      let currencyPostfix = 'us';
      // if (this.$i18n.locale === 'zh') {
      //   currencyPostfix = 'zh'
      // }
      const propKey = objKey + '_' + currencyPostfix;
      if (sourceObj) {
        return sourceObj[propKey] || sourceObj[objKey + '_' + 'en'] || '';
      }
      return '';
    },
    listPrice(sourceObj, region_id) {
      // console.log('listPrice',sourceObj)
      if (region_id == '1') {
        // Set list price for region 1
        return sourceObj.price.list_price_us;
      } else if (region_id == '2') {
        // Set list price for region 2
        return sourceObj.price.list_price_eu;
      } else if (region_id == '3') {
        // Set list price for region 3
        return sourceObj.price.list_price_cn;
      }
    },
    factoryPrice(sourceObj, region_id) {
      if (region_id == '1') {
        // Set factory price for region 1
        return sourceObj.price.factory_price_us;
      } else if (region_id == '2') {
        // Set factory price for region 2
        return sourceObj.price.factory_price_eu;
      } else if (region_id == '3') {
        // Set factory price for region 3
        return sourceObj.price.factory_price_cn;
      }
    },
  },
};
